const showToolTip = (el) => {
  el.setAttribute("data-tooltip", "Vínculo copiado al portapapeles.");
  el.classList.add("has-tooltip", "is-tooltip-active");
  setTimeout(() => el.classList.remove("has-tooltip", "is-tooltip-active"), 2000);
};

export const copyToClipboard = (event) => {
  event.preventDefault();
  const target = event.currentTarget;
  const content = target.href;
  navigator.clipboard.writeText(content).then(showToolTip(target))
};
