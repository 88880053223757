import { state } from "/store.js";
import promotions from "../../components/menus/promotions.vue";
import { copyToClipboard } from "../../js/copy-clipboard.js";
import { initGallery } from "../../js/gallery";
import { initSlide } from "../../js/slider";
import { initNews } from "../../js/slider-news";
import { initCollapsible } from "../../js/collapsible";
import { initModal } from "../../js/modal-form";
import { initVideos, resizeVideos } from "../../js/video";
import { initDelete } from "../../js/deleteNotification.js";
import { initDropDown } from "../../js/menu-dropdown";
import { initOrganizationChart } from "../../js/organization-chart";
import { langSwitcher } from "../../js/lang-switcher.js";
import { observeElement } from "../../js/utils/observeElement.js";
import { initDomsearch } from "../../js/contact-page-search.js";


function toggle(target) {
  if (!state.panel_active || state.panel_active != target) {
    state.panel_active = target;
    document.addEventListener("keydown", handleClose);
  } else {
    state.panel_active = null;
    document.removeEventListener("keydown", handleClose);
  }
}
function handleClose(e) {
  if (e.key === "Escape") {
    state.panel_active = null;
    document.removeEventListener("keydown", handleClose);
  }
}

function initSite() {
  initSlide(".block-slider");
  initGallery(".block-gallery");
  initNews();
  initCollapsible();
  initModal();
  initDropDown();
  initDelete();
  initVideos(".block-video iframe");
  resizeVideos(".block-video iframe");
  initOrganizationChart();
  langSwitcher();
  observeElement();
  initDomsearch();
}
export default {
  methods: {
    toggle,
    copyToClipboard,
  },
  components: {
    promotions,
  },
  computed: {
    search_active() {
      return state.panel_active == "#finder";
    },
    burger_active() {
      return state.panel_active == "#burger-main-menu";
    },
  },
  mounted() {
    initSite();
  },
  unmounted() {
    document.removeEventListener("keydown", handleClose);
  },
  setup() {
    return { state };
  },
};
