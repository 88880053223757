import Glide from "@glidejs/glide";

export const initNews = (selector = ".block-comunicational .glide") => {
  const slides = document.querySelectorAll(selector);
  if (slides.length) {
    const glideOptions = {
      type: "slider",
      perView: 3,
      bound: true,
      gap: 16,
      breakpoints: {
        1024: {
          perView: 2,
          gap: 16,
        },
        500: {
          perView: 1,
        },
      },
    };

    slides.forEach((element) => {
      let item = element;
      let d = new Date();
      let id = "slider-news-" + d.getTime();
      item.setAttribute("id", id);
      let slider = new Glide("#" + id, glideOptions);
      slider.mount();
    });
  }
};
