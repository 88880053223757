import ListFilter from "@mp3000mp/list-filter";

export const initDomsearch = () => {
  const trigger = document.getElementById("contact-input");
  const list = document.getElementById("contact-page-list");
  const noData = document.getElementById("contact-nodata");
  const selectValue = "";
  if (trigger) {
    new ListFilter(trigger, list, {
      onSearch: function (searchPattern, element, isMatching) {
        if (isMatching) {
          return (
            selectValue === "" || element.style.backgroundColor === selectValue
          );
        } else {
          return false;
        }
      },
      onAfterSearch: function (searchPattern, foundElements) {
        if (foundElements.length === 0) {
          noData.style.display = "";
        } else {
          noData.style.display = "none";
        }
      },
    });    
  }
};
