import Glide from "@glidejs/glide";

export const initSlide = (selector = ".block-slider .glide") => {
  const slides = document.querySelectorAll(selector);
  if (slides.length) {
    const glideOptions = {
      type: "carousel",
      perView: 1,
      autoplay: 6000,
      focusAt: "center",
      gap: 0,
    };

    slides.forEach((element) => {
      let item = element;
      let d = new Date();
      let id = "slider-" + d.getTime();
      item.setAttribute("id", id);
      let slider = new Glide("#" + id, glideOptions);
      slider.mount();
    });
  }
};
