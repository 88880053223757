
export function initDelete() {
  (document.querySelectorAll("#notification-area .delete") || []).forEach(
    ($delete) => {
      const $notification = $delete.parentNode.parentNode;
      if (sessionStorage.getItem("notification") == "closed") {
        $notification.parentNode.removeChild($notification);
      } else {
        $notification.classList.remove("is-hidden");
        $delete.addEventListener("click", () => {
          $notification.parentNode.removeChild($notification);
          sessionStorage.setItem("notification", "closed");
        });
      }
    },
  );
}