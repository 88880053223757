import Glide from "@glidejs/glide";
import { initModal } from "./modal-form";

class SlidesEventHandler {
  constructor(slides, events) {
    this.slides = slides;
    this.events = events;
  }
  addClass(active) {
    if (active) {
      active.classList.add("inactive");
    }
  }
  removeClass(active) {
    if (active) {
      active.classList.remove("inactive");
    }
  }
  mount() {
    this.events.on("move", (e) => {
      const active = this.slides[0];
      this.addClass(active);
    });
    this.events.on("move.after", (e) => {
      const active = this.slides[0];
      this.removeClass(active);
    });
  }
}

const Animation = (Events) => {
  const slide = document.querySelector(Events.selector);
  const activeSlides = slide.getElementsByClassName("glide__slide--active");
  return new SlidesEventHandler(activeSlides, Events);
};

const glideOptions = {
  type: "carousel",
  perView: 3,
  peek: 0,
  gap: 16,
  focusAt: "center",
  animationDuration: 500,
  animationTimingFunc: "cubic-bezier(.165, .840, .440, 1)",
  breakpoints: {
    769: {
      perView: 3,
    },
    768: {
      perView: 2,
    },
    480: {
      perView: 1,
    },
  },
};

function getAll(selector) {
  return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
}

const buildModal = (
  url,
  alt,
  full,
  target,
) => `<div id="${target}" class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-content">
    <p class="image is-16by9">
      <img src="${url}" alt="${alt}">
    </p>
  </div>
  <div class="glide__arrows" data-glide-el="controls">
    <button class="glide__arrow glide__arrow--left" data-glide-dir="<"><i class="fa-solid fa-chevron-left"></i></button>
    <button class="glide__arrow glide__arrow--right" data-glide-dir=">"><i class="fa-solid fa-chevron-right"></i></button>
  </div>
  <a href="${full}" class="glide__download icon is-large" download><i class="fa fa-download" aria-hidden="true"></i></a>
  <button class="modal-close is-large" aria-label="close"></button>
</div>`;

const doHash = () => Math.random().toString(36).substring(7);

function updateModal(glide, direction) {
  const galleryId = glide.selector;
  const gallery = document.querySelector(galleryId);
  const sliceActive = gallery.querySelector(".glide__slide--active");
  const imageModal = document.querySelector(".modal-content .image img");

  const nextSlice =
    direction == ">"
      ? sliceActive.nextElementSibling
      : sliceActive.previousElementSibling;

  const value = nextSlice.querySelector(".image a").getAttribute("href");
  imageModal.setAttribute("src", `${value}`);

  glide.go(direction);
}

const slideHandler = (event, glide) => {
  event.stopPropagation();
  event.preventDefault();
  const target = `modal-${doHash()}`;
  const rootEl = document.documentElement;
  const element = event.currentTarget;

  const alt = element.querySelector("img").getAttribute("title");
  const url = element.getAttribute("href");
  const full = element.dataset.originalImage;
  element.dataset.target = target;

  const wrapper = document.getElementById("wrapper");
  wrapper.insertAdjacentHTML("beforeend", buildModal(url, alt, full, target));

  const next = document.querySelectorAll(".glide__arrow");
  next.forEach((c) =>
    c.addEventListener("click", () => {
      updateModal(glide, c.dataset.glideDir);
    }),
  );

  wrapper.classList.add("is-clipped");
  const modal = document.getElementById(target);

  const modalCloses = getAll(
    ".modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button",
  );

  if (modalCloses.length > 0) {
    modalCloses.forEach(function (el) {
      el.addEventListener("click", function () {
        closeModals(rootEl, modal);
      });
    });
  }

  const closeModals = (rootEl, modal) => {
    rootEl.classList.remove("is-clipped");
    wrapper.classList.remove("is-clipped");
    modal.classList.remove("is-active");
    modal.remove();
  };
};

export const initGallery = (selector = ".block-gallery .glide") => {
  const galleries = document.querySelectorAll(selector);
  if (galleries.length) {
    galleries.forEach((slide) => {
      let hash = doHash();
      let id = `gallery-${hash}`;
      slide.setAttribute("id", id);

      let glide = new Glide(`#${id}`, glideOptions);

      glide.mount({ Animation });

      let childs = slide.querySelectorAll("figure.image > a");
      childs.forEach((c) =>
        c.addEventListener("click", function (e) {
          slideHandler(e, glide);
        }),
      );
    });
    initModal();
  }
};
