export function initOrganizationChart() {
  const org = document.getElementById("organigrama");

  if (org) {
    const wrapper = org.querySelector(".advices");
    const offices = org.querySelectorAll(".advice.level-1");
    const tree = org.querySelector(".org-tree");

    const root = tree.firstElementChild;
    wrapper.appendChild(root);

    let grid_right = document.createElement("div");
    grid_right.setAttribute("class", "grid-sides grid-right");
    wrapper.appendChild(grid_right);

    let grid_left = document.createElement("div");
    grid_left.setAttribute("class", "grid-sides grid-left");
    wrapper.appendChild(grid_left);

    offices.forEach((element, i) => {
      element.setAttribute("class", "advice level-1");
      if (i % 2 == 0) {
        if (offices.length > 2) {
          element.classList.add("group-left");
        }
        grid_left.appendChild(element);
      } else {
        if (offices.length > 3) {
          element.classList.add("group-right");
        }
        grid_right.appendChild(element);
      }
    });

    tree.style.visibility = `visible`;
  }
}
