export function langSwitcher() {
  const langSwitcher = document.getElementById("lang-switcher");
  const langSwitcherBtn = document.getElementById("lang-switcher-btn");

  if (langSwitcherBtn) {
    langSwitcherBtn.addEventListener("click", (event) => {
      langSwitcher.classList.toggle("is-active");
    });
  }
}
