<script setup>
import { state } from "/store.js";
import { onMounted } from "vue";

const API_ENDPOINT = "/search/promotions/";
const SEARCH_URL = "/search/";
const PROMOTIONS = [];

if (!state.promotions) {
  state.promotions = PROMOTIONS;
}

onMounted(() => {
  if (state.promotions.length == 0) {
    fetch(API_ENDPOINT).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          state.promotions = data;
        });
      }
    });
  }
});
</script>

<template>
  <li v-for="(item, index) in state.promotions" :key="index" class="icon-text">
    <span class="icon">
      <i class="fa-solid fa-arrow-up-right-from-square" aria-hidden="true"></i>
    </span>
    <a :href="`${SEARCH_URL}?query=${item.query}`" class="subtitle is-5" :title="`Leer ${item.title}`">
      {{ item.title }}
    </a>
  </li>
</template>
