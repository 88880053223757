/**
 * Observes an element and toggles a class on the target element based on visibility in the viewport.
 *
 * @param {string} [elToObserve="#main-content > *:first-child"] - The CSS selector of the element to observe. Default is the first child inside #main-content.
 * @param {number} [thresholdVal=0.2] - The percentage (from 0 to 1) of the element that must be visible before triggering the callback. Default is 0.2 (20%).
 * @param {string} [className="target-observed-hidden"] - The class to toggle on the target element when the observed element is not visible. Default is "target-observed-hidden".
 *
 * @returns {void} - Doesn't return any value, just toggles the className on the target element.
 */
export function observeElement(
  elToObserve = "#main-content > *:first-child",
  thresholdVal = 0.2,
  className = "target-observed-hidden",
) {
  const elementToObserve = document.querySelector(elToObserve);
  const targetElement = document.getElementById("main-navigation-navbar");

  if (!elementToObserve || !targetElement) return;

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        entry.isIntersecting
          ? targetElement.classList.remove(className)
          : targetElement.classList.add(className);
      });
    },
    {
      // NOTE: Trigger the callback when a percentage of the element is visible.
      threshold: thresholdVal,
    },
  );

  observer.observe(elementToObserve);
}
